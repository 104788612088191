import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql } from 'gatsby'
import { Grid, Row, Col } from 'react-bootstrap'
import { Parallax } from 'react-parallax'

const StrataFAQPage = ({ data }) => (
  <Layout>
    <SEO title="Strata FAQ" description="Frequently Asked Questions regarding Korecki Strata Property Management" keywords="korecki, faq, Real Estate, Strata Property Management services, Rent, Properties, Property Management, Client Property Account System, CPAS, Property Information"/>
    <div>
      {/*<Parallax bgImage={require('../../images/seawall_v2.jpg')} bgImageAlt="Strata FAQ" strength={200}>*/}
      {/*  <Grid>*/}
      {/*    <Row>*/}
      {/*      <Col md={12}>*/}
      {/*        <h1>{data.contentfulFaqList.title}</h1>*/}
      {/*      </Col>*/}
      {/*    </Row>*/}
      {/*  </Grid>*/}
      {/*</Parallax>*/}
      {/*<br />*/}
      {/*<Grid>*/}
      {/*  <Row>*/}
      {/*    <h3> Choose a question: </h3>*/}
      {/*  </Row>*/}
      {/*  <Row>*/}
      {/*    <Col md={12}>*/}
      {/*      <ul>*/}
      {/*        {data.contentfulFaqList.questions.map((item) =>*/}
      {/*          <li> <a href={"#" + item.slug}> {item.question}</a> </li>*/}
      {/*        )}*/}
      {/*      </ul>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}
      {/*  <hr />*/}
      {/*  <Row>*/}
      {/*    <Col md={12}>*/}
      {/*      <div>*/}
      {/*        {data.contentfulFaqList.questions.map((item) =>*/}
      {/*          <div id={item.slug}>*/}
      {/*            <br />*/}
      {/*            <h4> {item.question} </h4>*/}
      {/*            <p> {item.childContentfulFaqEntryAnswerTextNode.answer} </p>*/}
      {/*          </div>*/}
      {/*        )}*/}
      {/*      </div>*/}
      {/*    </Col>*/}
      {/*  </Row>*/}

      {/*</Grid>*/}
      <h1>Coming Soon</h1>
    </div>
  </Layout>
)


export const query = graphql`
    {
        contentfulFaqList(faqId: {eq: 2}) {
            title
            questions {
                question
                slug
                childContentfulFaqEntryAnswerTextNode {
                    answer
                }
            }
        }
    }`
export default StrataFAQPage
